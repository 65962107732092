.site-footer {
  background-color: #0c2139;
  background-image: url("../../assets//images/backgrounds/footer-bg-1-1.jpg");
  background-position: 0 0;
  background-size: cover;
  padding-bottom: 20px;
  padding-top: 65px;
}
.footer-widget{
    margin-bottom: 30px;
}

.footer-widget__about p{
    border-bottom: 1px solid hsla(0,0%,100%,.1);
    color: #8e96a0;
    font-size: 16px;
    line-height: 34px;
    margin-bottom: 30px;
    margin-top: 20px;
    padding-bottom: 20px;
}
.footer-widget__about-phone{
    align-items: flex-start;     
    display: flex;
    padding-top: 25px;
}
.footer-widget__about-phone>i{
    color: #008fd5 !important;
    font-size: 32px;
}
.footer-widget__about-phone-content{
    margin-left: 20px;
}
.footer-widget__about-phone-content span{
    color: #8e96a0 !important;
    display: block;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .1em;
    line-height: 1;
    margin-bottom: 7px;
    text-transform: uppercase;
}
.footer-widget__about-phone-content h3{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
}
.footer-widget__about-phone-content h3 a{
    color: #8e96a0 !important;
    transition: all .5s ease;
}
.footer-widget{
    margin-bottom: 30px;
}

@media(min-width:1200px){
    .footer-widget__link{
        margin-left: 40px;
    }
    .footer-widget__post{
        margin-left: 32px;
    }
    .footer-widget__contact{
        margin-left: 70px;
    }
}

.footer-widget__title{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}
.footer-widget__link-list{
    margin-bottom: 0;
    margin-top: 15px;
}

.footer-widget__link-list li a{
    color: #8e96a0;
    display: block;
    font-size: 16px;
    line-height: 40px;
    transition: all .5s ease;
    text-decoration: none;
}
.footer-widget__post-list{
    margin-bottom: 0;
    margin-top: 26px;
}
.footer-widget__post-list li{
    align-items: flex-start;
    display: flex;
    padding: 0.8rem 0rem;
}
.footer-widget__post-list li>img{
    flex-shrink: 0;
    width: 80px;
}
.footer-widget__post-list-content{
    margin-left: 20px;
}
.footer-widget__post-list-content span{
    color: #008fd5 !important;
    color: var(--thm-base);
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 14px;
}
.footer-widget__post-list-content h3{
    color: #8e96a0;
    color: var(--thm-text-dark);
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0;
}
.footer-widget__post-list-content h3 a{
    color: #8e96a0;
    text-decoration: none;
}

.footer-widget__contact{
    background-color: #09192c;
    padding: 40px 30px 33px;
}
.footer-widget__contact h3{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin: 0 0 20px;
}
.footer-widget__contact-list{
    margin: 0;
}
.footer-widget__contact-list li a{
    color: #8e96a0 !important;
    /* color: var(--thm-text-dark); */
    display: block;
    font-size: 14px;
    line-height: 24px;
    padding-left: 25px;
    position: relative;
    transition: all .5s ease;
    text-decoration: none;
}
.footer-widget__contact-list li a>i{
    color: #008fd5 !important;
    /* color: var(--thm-base); */
    font-size: 14px;
    left: 0;
    position: absolute;
    top: 6px;
    transition: all .5s ease;
}
.footer-widget__contact-list li+li{
    border-top: 1px solid hsla(0,0%,100%,.1);
    margin-top: 15px;
    padding-top: 15px;
}
.bottom-footer{
    background-color: #09192c;
    padding-bottom: 27.5px;
    padding-top: 27.5px;
}
.bottom-footer .container{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.bottom-footer p{
    color: #8e96a0 !important;
    font-size: 16px;
    margin: auto;
}
.bottom-footer__social{
    display: flex;
    flex-wrap: wrap;
}
.bottom-footer__social a{
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    color: #0c2139;
    display: flex;
    font-size: 12px;
    height: 41px;
    justify-content: center;
    transition: all .5s ease;
    width: 41px;
}
.fa-facebook-square:before{
    content: "\f082";
}
.bottom-footer__social a+a{
    margin-left: 10px;
}

@media(max-width:900px){
    .bottom-footer__social{
        margin: auto;
    }
    .bottom-footer .container{
        text-align: center;
        gap: 20px;
    }
    .footer-widget__contact h3{
        text-align: center;
    }
    .footer-widget__link-list{
        /* text-align: center; */
        margin-left: 40%;
    }
    .footer-widget__title{
        text-align: center;
    }
    .footer-widget__about-phone{
        justify-content: center;
    }
    .footer-widget> a> img{
        margin-left: 30%;
    }
    
}   