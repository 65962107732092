.feature-one{
    background-color: #eff2f6;
    padding-bottom: 90px;
    padding-top: 358px;
    position: relative;
}
.feature-one__shape-1{
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    bottom: 80px;
    left: 232px;
    position: absolute;
}
.feature-one__shape-2, .feature-one__shape-3{
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    position: absolute;
}
.feature-one__shape-2{
    right: 225px;
    top: 330px;
}
.feature-one__shape-3{
    left: 240px;
    top: 170px;
}
.block-title{
    margin-bottom: 50px;
}
.block-title p{
    align-items: center;
    color: #798490;
    color: var(--thm-color);
    display: inline-flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    position: relative;
    top: -10px;
}
.block-title p:after{
    background-color: #008fd5;
    background-color: var(--thm-base);
    border-radius: 4px;
    content: "";
    height: 3px;
    margin-left: 10px;
    width: 43px;
}
.feature-one .block-text{
    font-weight: 400;
    margin-top: 30px;
}
.block-text{
    color: #798490;
    color: var(--thm-color);
    font-size: 18px;
    line-height: 1.88;
    margin-bottom: 0;
}
.feature-one__box{

    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    margin-bottom: 30px;
    padding: 50px;
    position: relative;
    transition: all .5s ease;
}
.feature-one__box:before{
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    content: "";
    height: calc(100% - 4px);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.feature-one__box i{
    color: #008fd5;
    color: var(--thm-base);
    font-size: 60px;
    margin-right: 20px;
    position: relative;
}
.feature-one__box p{
    color: #0c2139;
    color: var(--thm-black);
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    position: relative;
}
.feature-one__box:hover{
    background-color: #004aa1 !important;
    background-color: var(--thm-primary);
}
.assets_icon_image{
    width:100px;
    z-index: 10;
    padding: 0rem 1rem;
    fill: #008fd5;
}
.assets_icon_image-eco{
    height: 68px;
    padding: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    width:100px;
    z-index: 10;
    fill: #008fd5;
}
.secand-div{
    margin-top: 5rem;
}