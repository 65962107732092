.feature-two__box{
    align-items: center;
    display: flex;
    margin-bottom: 30px;
    padding: 25px 15px;
    position: relative;
}
.feature-two__box>i{
    color: var(--thm-base);
    font-size: 60px;
    position: relative;
    transition: all .5s ease;
    z-index: 10;
}
.feature-two__box p{
    color: #000;
    cursor: pointer;
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 0 20px;
    max-width: 160px;
    position: relative;
    transition: all .5s ease;
    z-index: 10;
}
.about-one__form-content{
    padding: 40px 60px 60px;
}
.about-one__form .input-box__top{
    align-items: center;
    color: #798490;
    color: var(--thm-color);
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    margin-bottom: 12px;
}
.about-one__form .input-box{
    margin-bottom: 30px;
}
.about-one__form-content p{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    justify-content: space-between;
    margin: 0;
}
.about-one__form-content p+p{
    border-top: 1px solid #e6eaef;
    margin-top: 30px;
    padding-top: 30px
}

.about-one__form-content p span{
    line-height: 1;
}
.about-one__form-content p b{
    /* color: #0c2139; */
    color: var(--thm-black);
    font-weight: 500;
    line-height: 1;
}
.about-one__form .thm-btn{
    margin-top: 60px;
    padding-bottom: 17.5px;
    padding-top: 17.5px;
    width: 100%;    
}
.about-one__form{
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 10px 60px 0 rgba(0,0,0,.1);
    margin-left: 70px;
    margin-top: -130px;
    position: relative;
    z-index: 10;
}

.about-one__form h3{
    background:linear-gradient(to right, hsl(210 84% 62% / 46), #6f59d2);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    padding-bottom: 52px;
    padding-top: 42px;
    position: relative;
    text-align: center;
}
.thm-btn{
    background: linear-gradient(to right, hsl(210 84% 62% / 46), #6f59d2);
    border: none;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-family: Rubik,sans-serif;
    font-family: var(--thm-font);
    font-size: 16px;
    font-weight: 500;
    outline: none!important;
    padding: 16.5px 39.5px;
    transition: .5s;
    vertical-align: middle;
}

.about-one__content{
    padding-top: 120px;
}
.block-title{
    margin-bottom: 50px;
}
.about-one__content p{
    margin: 0;
}
.block-title p{
    align-items: center;
    color: #798490;
    color: var(--thm-color);
    display: inline-flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    position: relative;
    top: -10px;
}
.block-title p:after{
    background-color: #008fd5 !important;
    background-color: var(--thm-base);
    border-radius: 4px;
    content: "";
    height: 3px;
    margin-left: 10px;
    width: 43px;
}
.block-title h2{
    color: var(--thm-black);
    font-size: 60px;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
}
.about-one__content .about-one__text{
    color: #004aa1 !important;
    color: var(--thm-primary);
    font-size: 24px;
    line-height: 36px;
    margin: -10px 0 40px;
}

.only-p{
    padding-bottom: 10rem;
}
.about-one__content-about{
    padding-top: 60px;
}

@media (min-with:1200px) {
    .block-title h2{
        font-size: 60px !important;
        line-height: 1;   
    }
}

@media (max-width: 900px){
    .about-one__form{
        margin-left: 0;
        margin-top: 15%;
    }
    .block-title h2{
        font-size: 36px;
    }
    .only-p{
        padding-bottom: 5rem;
    }
    .about-one__content .about-one__text{
        font-size: 20px;
    }
    .about-one__content{
        padding-top: 50px;
    }
}