.service-one{
    margin-bottom: -236px;
    padding-top: 120px;
    position: relative;
    z-index: 10;
}
.block-title{
    margin-bottom: 50px;
}
.block-title p{
    align-items: center;
    color: #798490;
    color: var(--thm-color);
    display: inline-flex;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    position: relative;
    top: -10px;
}
.block-title p:after{
    background-color: #008fd5 !important;
    background-color: var(--thm-base);
    border-radius: 4px;
    content: "";
    height: 3px;
    margin-left: 10px;
    width: 43px;
}
.service-one__card{
    border-radius: 4px;
}
.service-one__image{
    background-color: #0c2139 !important;
    background-color: var(--thm-black);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}
.service-one__image>img{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: opacity .5s ease,-webkit-transform .5s ease;
    transition: transform .5s ease,opacity .5s ease;
    transition: transform .5s ease,opacity .5s ease,-webkit-transform .5s ease;
    width: 100%;
}
.service-one__card:hover .service-one__image>img{
transform: scale(1.05);
opacity: 0.7;
}
.service-one__content{
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 10px 60px 0 rgba(0,0,0,.1);
    padding: 35px 40px 50px;
    position: relative;
}
.service-one__content h3{
    color: #0c2139;
    color: var(--thm-black);
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 15px;
}
.service-one__content h3 a{

    color: inherit;
    transition: all .5s ease
}
.service-one__card:hover .service-one__link{
    background-color: #004aa1;
    color: white !important;
}
.service-one__link{
    align-items: center;
    background: linear-gradient(to right, hsl(210 84% 62% / 46), #6f59d2);
    border-radius: 50%;
    bottom: 0;
    cursor: pointer;
    box-shadow: 0 10px 60px 0 rgba(0,0,0,.1);
    color: white !important;
    display: flex;
    font-size: 32px;
    height: 68px;
    justify-content: center;
    position: absolute;
    right: 40px;
    text-align: center;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    transition: all .5s ease;
    width: 68px;
}

@media (max-width:900px){
    .service-one__card{
        padding: 1.8rem 0rem    ;
    }
}